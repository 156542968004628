import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import {NumberFormat} from "@/helpers/NumberFormat";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

// https://github.com/bpampuch/pdfmake/blob/master/src/standardPageSizes.js
export default class {
  constructor(data) {
    this.data = data
    this._create()
  }
  async create() {
    // var win = window.open('', '_blank');
    this.dd = {
      pageSize: 'legal',
      // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
      pageMargins: [ 40, 70, 40, 40 ],
      // by default we use portrait, you can change it to landscape if you wish
      pageOrientation: 'landscape',
      header: [
        {
          columns:[
            {
              text:this.data.date,
              bold: true,
              margin: [40, 25, 0, 0],
              fontSize: 18,
              width:'30%'
            },
            {
              text:'BITACORA DE ENTRADAS',
              bold: true,
              margin: [0, 25, 0, 0],
              fontSize: 18,
              width:'40%',
              alignment: 'center'
            },
            {
              text: '',
              width:'30%'
            },
          ],
        },
        {
          image:await this.getBase64ImageFromURL(
            process.env.VUE_APP_BASE_URL+require('@/assets/logo.png')
          ),
          width: 130,
          absolutePosition: {
            x: 840,
            y: -15
          }
        }
      ],
      content: this.content,
      styles: {
        verticalCenter: {
          margin: [0, 10, 0, 0],
        },
        tableHeader: {
          bold: true
        },
        cellDefault: {
          fontSize: 10,
          alignment: 'center'
        },
        smallText: {
          fontSize: 10
        },
        date: {
          bold: true,
          fontSize: 18
        }
      }
    }
    return pdfMake.createPdf(this.dd)
  }
  _widths() {
    return [50, 80, 50, 50, 50, 50, 50, 50, 'auto', 'auto', 'auto', 'auto'/*, 'auto'*/, 'auto', 'auto']
    //return ['auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto']
  }
  _header() {
    return [
      {
        text: 'pax',
        style: ['verticalCenter', 'tableHeader'],
        rowSpan: 2,
        alignment: 'center',
      },
      {
        text: 'tour',
        style: ['verticalCenter', 'tableHeader'],
        rowSpan: 2,
        alignment: 'center'
      },
      {
        text: 'Paypal',
        style: 'tableHeader',
        colSpan: 2,
        alignment: 'center'
      },
      {},
      {
        text: 'Sol. Paypal',
        style: 'tableHeader',
        colSpan: 2,
        alignment: 'center'
      },
      {},
      {
        text: 'Efectivo',
        style: 'tableHeader',
        colSpan: 2,
        alignment: 'center'
      },
      {},
      {
        text: 'Evopay Sol.',
        style: ['verticalCenter', 'tableHeader'],
        rowSpan: 2,
        alignment: 'center'
      },
      {
        text: 'Clip Terminal',
        style: ['verticalCenter', 'tableHeader'],
        rowSpan: 2,
        alignment: 'center'
      },
      {
        text: 'Clip Sol.',
        style: ['verticalCenter', 'tableHeader'],
        rowSpan: 2,
        alignment: 'center'
      },
      {
        text: 'Tpv Banco',
        style: ['verticalCenter', 'tableHeader'],
        rowSpan: 2,
        alignment: 'center'
      },
      /*{
        text: 'Cheque',
        style: ['verticalCenter', 'tableHeader'],
        rowSpan: 2,
        alignment: 'center'
      },**/
      {
        text: 'Transf./Deposito',
        style: ['verticalCenter', 'tableHeader'],
        rowSpan: 2,
        alignment: 'center'
      },
      {
        text: 'Cortesia',
        style: ['verticalCenter', 'tableHeader'],
        rowSpan: 2,
        alignment: 'center'
      },
    ]
  }
  _subheader() {
    return [
      '',//pax
      '',// tour
      {
        text: 'USD',
        style: 'tableHeader',
        alignment: 'center'
      },
      {
        text: 'MXN',
        style: 'tableHeader',
        alignment: 'center'
      },
      {
        text: 'USD',
        style: 'tableHeader',
        alignment: 'center'
      },
      {
        text: 'MXN',
        style: 'tableHeader',
        alignment: 'center'
      },
      {
        text: 'USD',
        style: 'tableHeader',
        alignment: 'center'
      },
      {
        text: 'MXN',
        style: 'tableHeader',
        alignment: 'center'
      },
      '',//Evopay Sol
      '',//Clip Terminal
      '',//Cl
      '',//Tpv Banco
      //'',//Cheque
      '',//Transf./Deposito
      '',//Cortesia
    ]
  }
  _create() {
    //console.log('this.data', this.data);
    //console.log('_buildBody', this._buildBody());
    this.content = {
      //style: 'tableExample',
      //color: '#444',
      table: {
        widths: this._widths(),
        headerRows: 2,
        // keepWithHeaderRows: 1,
        body: [
          this._header(),
          this._subheader(),
          ...this._buildBody(),
          ...this._buildTotals()
        ]
      }
    }
  }
  _buildBody() {
    let rows = []
    const items = this.data.items
    for (const p of items) {
      rows.push([
        this.getCellBody(p.pax),
        this.getCellBody(`${p.tour}\n${p.code}`),
        this.getCellBody(p.payments.paypal.usd ? NumberFormat.format(p.payments.paypal.usd, 2, ',', '.') : ''),
        this.getCellBody(p.payments.paypal.mxn ? NumberFormat.format(p.payments.paypal.mxn, 2, ',', '.') : ''),
        this.getCellBody(p.payments.paypalRq.usd ? NumberFormat.format(p.payments.paypalRq.usd, 2, ',', '.') : ''),
        this.getCellBody(p.payments.paypalRq.mxn ? NumberFormat.format(p.payments.paypalRq.mxn, 2, ',', '.') : ''),
        this.getCellBody(p.payments.cash.usd ? NumberFormat.format(p.payments.cash.usd, 2, ',', '.') : ''),
        this.getCellBody(p.payments.cash.mxn ? NumberFormat.format(p.payments.cash.mxn, 2, ',', '.') : ''),
        this.getCellBody(p.payments.evopayRq.mxn ? NumberFormat.format(p.payments.evopayRq.mxn, 2, ',', '.') : ''),
        this.getCellBody(p.payments.clipTerminal.mxn ? NumberFormat.format(p.payments.clipTerminal.mxn, 2, ',', '.') : ''),
        this.getCellBody(p.payments.clipRq.mxn ? NumberFormat.format(p.payments.clipRq.mxn, 2, ',', '.') : ''),
        this.getCellBody(p.payments.tpvBank.mxn ? NumberFormat.format(p.payments.tpvBank.mxn, 2, ',', '.') : ''),
        // this.getCellBody(p.payments.bankCheque.mxn ? p.payments.bankCheque.mxn : ''),
        this.getCellBody(p.payments.transferDeposit.mxn ? NumberFormat.format(p.payments.transferDeposit.mxn, 2, ',', '.') : ''),
        this.getCellBody(p.payments.courtesy.authorizedBy ? p.payments.courtesy.authorizedBy : ''),
      ])
    }
    return rows
  }
  _buildTotals() {
    let rows = []
    rows.push([
      { colSpan:2, text: 'Subtotales', style: 'tableHeader' },
      {},
      this.getCellBody(this.data.totals.totalPaypalUSD),
      this.getCellBody(this.data.totals.totalPaypalMXN),
      this.getCellBody(this.data.totals.totalPaypalRqUSD),
      this.getCellBody(this.data.totals.totalPaypalRqMXN),
      this.getCellBody(this.data.totals.totalCashUSD),
      this.getCellBody(this.data.totals.totalCashMXN),
      this.getCellBody(this.data.totals.totalEvopayRq),
      this.getCellBody(this.data.totals.totalClipTerminal),
      this.getCellBody(this.data.totals.totalClipRq),
      this.getCellBody(this.data.totals.totalTpvBank),
      //this.getCellBody(this.data.totals.totalBankCheque),
      this.getCellBody(this.data.totals.totalTransferDeposit),
      this.getCellBody(''),
    ])
    const totalUSD = this.data.totals.totalPaypalUSD +
      this.data.totals.totalPaypalRqUSD +
      this.data.totals.totalCashUSD
    const totalMXN = this.data.totals.totalPaypalMXN +
      this.data.totals.totalPaypalRqMXN +
      this.data.totals.totalCashMXN +
      this.data.totals.totalEvopayRq +
      this.data.totals.totalClipTerminal +
      this.data.totals.totalClipRq +
      this.data.totals.totalTpvBank +
      this.data.totals.totalBankCheque +
      this.data.totals.totalTransferDeposit
    rows.push([
      { colSpan:2, text: 'Total USD', style: 'tableHeader' },
      {},
      { colSpan:12, text: `$${NumberFormat.format(totalUSD, 2, ',', '.')}`, style: 'tableHeader' },
      {},
      {},
      {},
      {},
      {},
      {},
      {},
      {},
      {},
      //{},
      {},
      {},
    ])
    rows.push([
      { colSpan:2, text: 'Total MXN', style: 'tableHeader' },
      {},
      { colSpan:12, text: `$${NumberFormat.format(totalMXN, 2, ',', '.')}`, style: 'tableHeader' },
      {},
      {},
      {},
      {},
      {},
      {},
      {},
      {},
      {},
      //{},
      {},
      {},
    ])
    return rows
  }
  getCellBody(text) {
    return {
      text: text,
      style: ['cellDefault']
    }
  }
  getBase64ImageFromURL(url) {
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.setAttribute("crossOrigin", "anonymous");
      img.onload = () => {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        var dataURL = canvas.toDataURL("image/png");
        resolve(dataURL);
      };
      img.onerror = error => {
        reject(error);
      };
      img.src = url;
    });
  }
}