<template>
  <div style="">
    <portal to="title-page">
      <h1>Reporte de reservaciones diario</h1>
    </portal>
    <v-card
      elevation="2"
    >
      <v-card-text>
        <!-- <Loading :active="loader" :fullPage="false"/> -->
        <Loading :active="loading"/>
        <v-row>
          <v-col
            cols="12"
            sm="4"
            md="4"
          >
            <v-menu
              v-model="menu2"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="date"
                  label="Fecha"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="date"
                @input="menu2 = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="4" md="5">
            <v-btn
              class="mt-md-3"
              small
              elevation="2"
              color="primary"
              @click="searchReservations()"
            >
              <v-icon>mdi-magnify</v-icon> Buscar
            </v-btn>
            <v-btn 
              class="ml-2 mt-md-3 white--text"
              small 
              color="red"
              @click="generatePdf"
            >
              <v-icon>mdi-file-pdf-box</v-icon> Descargar
            </v-btn>
          </v-col>
          <!-- <v-col cols="6" sm="6" md="6">
            <v-btn @click="$router.push({path:'/backend/admin/currencies/create'})" small color="primary"><v-icon>mdi-plus-thick</v-icon> Moneda</v-btn>
          </v-col> -->
        </v-row>
        <div v-if="result" class="table-responsive">
          <table class="table table-bordered text-center">
            <thead>
              <tr>
                <th rowspan="2" class="">
                  Pax
                </th>
                <th rowspan="2" class="">
                  Tour
                </th>
                <th colspan="2" class="">
                  Paypal
                </th>
                <th colspan="2" class="">
                  Sol.Paypal
                </th>
                <th colspan="2" class="">
                  Efectivo
                </th>
                <th rowspan="2" class="">
                  Evopay Sol.
                </th>
                <th rowspan="2" class="">
                  Clip Terminal
                </th>
                <th rowspan="2" class="">
                  Clip Sol.
                </th>
                <th rowspan="2" class="">
                  TPV Banco
                </th>
                <!-- <th rowspan="2" class="">
                  Cheque
                </th> -->
                <th rowspan="2" class="">
                  Transf./Deposito
                </th>
                <th rowspan="2" class="">
                  Cortesia
                </th>
              </tr>
              <tr>
                <th>USD</th>
                <th>MXN</th>
                <th>USD</th>
                <th>MXN</th>
                <th>USD</th>
                <th>MXN</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="p in result.items"
                :key="p.code"
              >
                <td>{{ p.pax }}</td>
                <td>{{ p.tour }} <br> {{p.code}}</td>
                <td>{{ p.payments.paypal.usd ? p.payments.paypal.usd : '' }}</td>
                <td>{{ p.payments.paypal.mxn ? p.payments.paypal.mxn : '' }}</td>
                <td>{{ p.payments.paypalRq.usd ? p.payments.paypalRq.usd : '' }}</td>
                <td>{{ p.payments.paypalRq.mxn ? p.payments.paypalRq.mxn : '' }}</td>
                <td>{{ p.payments.cash.usd ? p.payments.cash.usd : '' }}</td>
                <td>{{ p.payments.cash.mxn ? p.payments.cash.mxn : '' }}</td>
                <td>{{ p.payments.evopayRq.mxn ? p.payments.evopayRq.mxn : '' }}</td>
                <td>{{ p.payments.clipTerminal.mxn ? p.payments.clipTerminal.mxn : '' }}</td>
                <td>{{ p.payments.clipRq.mxn ? p.payments.clipRq.mxn : '' }}</td>
                <td>{{ p.payments.tpvBank.mxn ? p.payments.tpvBank.mxn : '' }}</td>
                <!-- <td>{{ p.payments.bankCheque.mxn ? p.payments.bankCheque.mxn : '' }}</td> -->
                <td>{{ p.payments.transferDeposit.mxn ? p.payments.transferDeposit.mxn : '' }}</td>
                <td>{{ p.payments.courtesy.authorizedBy ? p.payments.courtesy.authorizedBy : '' }}</td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th colspan="2">Subtotales</th>
                <th>{{ result.totals.totalPaypalUSD }}</th>
                <th>{{ result.totals.totalPaypalMXN }}</th>
                <th>{{ result.totals.totalPaypalRqUSD }}</th>
                <th>{{ result.totals.totalPaypalRqMXN }}</th>
                <th>{{ result.totals.totalCashUSD }}</th>
                <th>{{ result.totals.totalCashMXN }}</th>
                <th>{{ result.totals.totalEvopayRq }}</th>
                <th>{{ result.totals.totalClipTerminal }}</th>
                <th>{{ result.totals.totalClipRq }}</th>
                <th>{{ result.totals.totalTpvBank }}</th>
                <!-- <th>{{ result.totals.totalBankCheque }}</th> -->
                <th>{{ result.totals.totalTransferDeposit }}</th>
                <th></th>
              </tr>
              <tr>
                <th colspan="2">Total USD</th>
                <th colspan="13" class="text-left">
                  $
                  {{
                    (result ? result.totals.totalPaypalUSD : 0) +
                    (result ? result.totals.totalPaypalRqUSD : 0) +
                    (result ? result.totals.totalCashUSD : 0)
                  }}
                </th>
              </tr>
              <tr>
                <th colspan="2">Total MXN</th>
                <th colspan="13" class="text-left">
                  $
                  {{
                    result.totals.totalPaypalMXN +
                    result.totals.totalPaypalRqMXN +
                    result.totals.totalCashMXN +
                    result.totals.totalEvopayRq +
                    result.totals.totalClipTerminal +
                    result.totals.totalClipRq +
                    result.totals.totalTpvBank +
                    result.totals.totalBankCheque +
                    result.totals.totalTransferDeposit
                  }}
                </th>
              </tr>
            </tfoot>
          </table>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import Vue from "vue";
import Reservation from "@/models/Reservation"
import DailyPDF from "@/pdfs/reports/DailyPDF"
import Loading from "@/components/Loading"
Vue.use('Loading', Loading);
import "@/assets/css/tables.css"
export default {
  name:'TaquillaDailyReport',
  //components: { Loading },
  data() {
    let date = this.$moment()
    return {
      date:date.format('YYYY-MM-DD'),
      //date: '2021-02-12',
      reservationModel: new Reservation,
      //items: null,
      result: null,
      menu2: false,
      loading: true,
      //unsubscribe: null,
    }
  },
  created () {
    this.$store.commit('SET_LAYOUT', 'Taquilla')
  },
  async mounted() {
    await this.searchReservations();
  },
  methods:{
    async searchReservations() {
      /*if (this.unsubscribe) {
        this.unsubscribe();
      }*/
      this.loading = true
      //this.unsubscribe = this.reservationModel.findByDate(this.$moment(this.date)).onSnapshot(snap => {
      this.result = await this.reservationModel.getDataDailyReport(this.$moment(this.date))
      //this.items = this.result.items
      this.loading = false
    },
    async generatePdf() {
      this.loading = true
      const pdf = new DailyPDF({date:this.$moment(this.date).format('DD MMMM YYYY'), ...this.result})
      const file = await pdf.create()
      file.download()
      this.loading = false
    }
  },
  //computed: mapState(['dialogConfirmationInfo'])
}
</script>